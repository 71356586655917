<template>
  <div id="back">
    <van-nav-bar :title="title" @click-right="onClickRight">
      <template #right>
        <van-icon name="friends-o" size="22" />
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  props: ["title", "back"],
  methods: {
    onClickRight() {
      this.$router.push("/myself/myselfRole");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/theme";

#back {
  .van-nav-bar {
    background: $theme-color;
  }

  .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff !important;
    font-size: 0.18rem;
  }
}
</style>