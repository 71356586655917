<template>
  <div id="myself">
    <Mine title="我的"></Mine>
    <main>
      <!-- 头部 -->
      <div class="top-info" @click="linkTo('/myself/myselfUser')">
        <div class="info-left">
          <van-image round width="48" height="48" :src="(userInfo && userInfo.headPicUrl) || headImgSrc" />
          <div class="left-text">
            <p>{{ userInfo && userInfo.fullName }}</p>
            <p>账号：{{ userInfo && userInfo.mobile }}</p>
          </div>
        </div>
        <div class="info-right">
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 分栏 -->
      <van-cell-group>
        <van-cell title="设置" is-link icon="setting-o" @click="linkTo('/myself/myselfSetting')" />
        <van-cell v-if="userInfo.openId" title="微信解绑" icon="wechat" style="color: #5ac86e" value="已绑定"
          @click="onClearWechat" />
        <van-cell v-else title="微信绑定" icon="wechat" style="color: #5ac86e" value="未绑定" @click="onWechat" />
        <van-cell v-if="roleCode == 3011" title="带看二维码" is-link icon="qr" @click="onQrshow" />
      </van-cell-group>
      <section class="item" @click="linkTo('/myself/myselfRole')">
        <span>切换角色</span>
      </section>
      <section class="item" @click="loginOut">
        <span>退出登录</span>
      </section>
    </main>
    <Footer></Footer>
    <!-- 看房二维码 -->
    <van-overlay :show="qrshow" @click="qrshow = false">
      <div class="wrapper">
        <vue-qr :logoSrc="headImgSrc" :text="qrtext" :size="200"></vue-qr>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import vueQr from "vue-qr";
import Footer from "@/components/footer";
import Mine from "@/components/mine";
import { getUserInfo } from "@/api/mine/mine";
import { clearWechat } from "@/api/myself/myself";
import { Dialog } from "vant";
import { Toast } from "vant";

export default {
  components: { Footer, Mine, vueQr },
  data() {
    return {
      headImgSrc: require("../../assets/images/myself/logo.png"),
      qrshow: false,
      userInfo: "",
      projectId: "",
      roleCode: "",
      host: process.env.VUE_APP_URL,
    };
  },
  computed: {
    qrtext() {
      return (
        "https://ng.eastecp.com/#/clue/clueScan?userId=" +
        this.userInfo.userId +
        "&projectId=" +
        this.projectId +
        "&spId=" +
        this.userInfo?.spInfo?.spId
      );
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    linkTo(path) {
      this.$router.push(path);
    },

    // 【请求】获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfo = res.data;
        this.projectId = res.data.selectedProjectIdSet[0];
        this.roleCode = res.data.empInfo.roleInfo.roleCode;
      });
    },

    // 【监听】微信绑定
    onWechat() {
      const APPID = process.env.VUE_APP_ID;
      const REDIRECT_URI = encodeURIComponent(
        "https://ng.eastecp.com/#/myself/wxAuth"
      );
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_base#wechat_redirect`;
      window.location.href = url;
    },

    // 【监听】微信解绑
    onClearWechat() {
      Dialog.confirm({
        title: "微信解绑",
        message: "确定解除该绑定吗？",
      }).then(() => {
        clearWechat().then((res) => {
          if (res.success) {
            Toast("解绑成功");
            this.getUserInfo();
          }
        });
      });
    },

    // 【监听】退出登录
    loginOut() {
      Dialog.confirm({
        message: "确定要退出当前账号吗？",
      }).then(() => {
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("accessToken");
        this.$router.push("/mine/login");
        setTimeout(function () {
          Toast({
            message: "退出成功",
            position: "top",
          });
        }, 500);
      });
    },

    // 【监听】看房
    onQrshow() {
      getUserInfo().then((res) => {
        this.userInfo = res.data;
        this.projectId = res.data.selectedProjectIdSet[0];
        this.roleCode = res.data.empInfo.roleInfo.roleCode;
        this.qrshow = true;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#myself {
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex: 1;
    overflow: auto;
    background: $bg-color;
  }

  .top-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem;
    background: $theme-color;
    margin-bottom: 0.15rem;
  }

  .info-left {
    display: flex;
  }

  .left-text {
    display: flex;
    flex-direction: column;
    margin-left: 0.1rem;
    color: #fff;
    justify-content: space-around;
  }

  .info-right {
    color: #fff;
  }

  // 看房二维码
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
  }
}

.item {
  margin: .1rem 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: .1rem;
  font-size: .14rem;
}
</style>
